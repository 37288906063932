import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>Acerca de Random Tech Talks</h1>
    <hr className="line" />
    <p className="introduction">
      Random Tech Talks es un podcast dedicado a la tecnología y todos los lugares donde se nota su influencia. Desde que empezó el siglo XXI la tecnología ha bañado prácticamente todos los ámbitos de la vida diaria, de los planes, las ideas y la forma en que los humanos interactuamos con el mundo. El programa es conducido por <a href="https://twitter.com/merediz">Raúl Merediz</a> y <a href="https://spaminvoluntario.com">Darío Vasconcelos</a>, amigos y socios de largo tiempo, activos y apasionados del mundo tecnológico.
    </p>
    <p className="introduction">
      Dale una vuelta a <a href="https://www.hearthisidea.com/episodes" target="_blank" rel="noopener noreferrer">nuestros episodios</a>, y si te gusta el show escríbenos, recomiéndanos, y suscríbete en la plataforma donde prefieras escuchar podcasts.
    </p>
    <h2>Dónde escucharnos</h2>

    <div className="listen-container">
      <a
        className="listen"
        href="https://open.spotify.com/show/7nP9rIbGdzQ9WG6vNgASsU"
        target="_blank"
        rel="noopener noreferrer"
      >
        Spotify
      </a>
      <a
        className="listen"
        href="https://rtt.show/episodes"
      >
        Sitio web
      </a>
      <a
        className="listen"
        href="https://apple.co/3q81FnX"
        target="_blank"
        rel="noopener noreferrer"
      >
        Apple Podcasts
      </a>
      <a
        className="listen"
        href="https://podcasts.google.com/feed/aHR0cHM6Ly9ydHQuc2hvdy9yc3MueG1s"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Podcasts
      </a>
    </div>
    <h2>Redes sociales</h2>
    <p className="introduction">
      Nos encanta recibir comentarios de todo tipo: sugerencias de temas, correcciones, 
      preguntas y hasta hate mail. Los puedes dejar en nuestro {" "}
      <a
        href="https://forms.gle/qGeB2d7xPN8235tF9"
        target="_blank"
        rel="noopener noreferrer"
      >
        formulario anónimo de contacto.
      </a>{" "}
      . También nos encuentras en Facebook, Twitter, Instagram, etc:
    </p>
    <div className="listen-container">
      <a
        className="listen"
        href="https://twitter.com/randomtechtalks"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
      <a
        className="listen"
        href="https://www.facebook.com/randomtechtalks"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
      <a
        className="listen"
        href="https://www.instagram.com/rttpodcast/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
    </div>
  </Layout>
)

export default IndexPage
